import * as React from 'react';
import './index.css';

class KaliperApp extends React.Component {
    constructor(props) {
      super(props);
      this.state={message:"", status:""};
      this.handleSubmit = this.handleSubmit.bind(this);
      this.Demographics = React.createRef();
      this.Diagnoses = React.createRef();
      this.Labs = React.createRef();
      this.Procedures = React.createRef();
      this.Meds = React.createRef();
      this.Observations = React.createRef();
      this.Assessments = React.createRef();
      this.Notes = React.createRef();

      this.Claims = React.createRef();
      this.EHR = React.createRef();
      this.Images = React.createRef();
      this.Genomics = React.createRef();

      this.Raw = React.createRef();
      this.Curated = React.createRef();
      this.CuratedAndHumanValidated = React.createRef();

      this.BespokeReport = React.createRef();
      this.ConfigurableDashboard = React.createRef(); 
      this.DaaS = React.createRef();

      this.Unharmonized = React.createRef(); 
      this.HarmonizedToControlledVocabularies = React.createRef();
      this.HarmonizedToCanonicalOntologyndHumanValidated = React.createRef();

      this.Oncology = React.createRef();
      this.Autoimmune = React.createRef(); 
      this.Cardiovascular = React.createRef(); 
      this.InfectiousDisease = React.createRef(); 
      this.Endocrinology = React.createRef(); 
      this.Neurology = React.createRef(); 
      this.Ophthalmology = React.createRef(); 
      this.Psychiatry = React.createRef(); 
      this.Respiratory = React.createRef(); 
      this.Gastrointestinal = React.createRef(); 
      this.Nephrology = React.createRef(); 
      this.Reproductive = React.createRef(); 
      this.Dermatology = React.createRef(); 
      this.Orthopedics = React.createRef(); 
      this.Urology = React.createRef(); 
      this.Genetic = React.createRef(); 
      this.Audiology = React.createRef(); 
      this.SleepDisorders = React.createRef();

      this.Email = React.createRef();
    }

    calcDataQuality(raw, curated, curatedAndHumanValidated) {
        if(raw) return "Raw";
        else if(curated) return "Curated";
        else if(curatedAndHumanValidated) return "CuratedAndHumanValidated";
        else return "";
    }


    calcFlexibilityOfUsage(BespokeReport, ConfigurableDashboard, DaaS) {
        if(BespokeReport) return "BespokeReport";
        else if(ConfigurableDashboard) return "ConfigurableDashboard";
        else if(DaaS) return "DaaS";
        else return "";
    }

    calcEaseOfUsage(Unharmonized, HarmonizedToControlledVocabularies, HarmonizedToCanonicalOntologyndHumanValidated) {
        if(Unharmonized) return "Unharmonized";
        else if(HarmonizedToControlledVocabularies) return "HarmonizedToControlledVocabularies";
        else if(HarmonizedToCanonicalOntologyndHumanValidated) return "HarmonizedToCanonicalOntologyndHumanValidated";
        else return "";
    }

    calcSpeciality(
        Oncology,       
        Autoimmune,
        Cardiovascular,
        InfectiousDisease,
        Endocrinology,
        Neurology,
        Ophthalmology,
        Psychiatry,
        Respiratory,
        Gastrointestinal,
        Nephrology,
        Reproductive,
        Dermatology,
        Orthopedics,
        Urology,
        Genetic,
        Audiology,
        SleepDisorders) {

        if(Oncology) return "Oncology";
        else if(Autoimmune) return "Autoimmune";
        else if(Cardiovascular) return "Cardiovascular";
        else if(InfectiousDisease) return "InfectiousDisease";
        else if(Endocrinology) return "Endocrinology";
        else if(Neurology) return "Neurology";
        else if(Ophthalmology) return "Ophthalmology";
        else if(Psychiatry) return "Psychiatry";
        else if(Respiratory) return "Respiratory";
        else if(Gastrointestinal) return "Gastrointestinal";
        else if(Nephrology) return "Nephrology";
        else if(Reproductive) return "Reproductive";
        else if(Dermatology) return "Dermatology";
        else if(Orthopedics) return "Orthopedics";
        else if(Urology) return "Urology";
        else if(Genetic) return "Genetic";
        else if(Audiology) return "Audiology";
        else if(SleepDisorders) return "SleepDisorders";
        else return "";
    }

    isValid(data) {
      var m = "";
      if(!this.data.Demographics &&
        !this.data.Diagnoses && 
        !this.data.Labs && 
        !this.data.Procedures && 
        !this.data.Meds && 
        !this.data.Observations && 
        !this.data.Assessments && 
        !this.data.Notes) {
          m = "<div style='color:pink;font-size: 20px;'>Please select atleast one data element. </div>";
          this.setState({message: m, status:"Error"});          
      }
      if(!this.data.Claims &&
        !this.data.EHR && 
        !this.data.Images && 
        !this.data.Genomics) {
          m = m + "<div style='color:pink;font-size: 20px;'>Please select at least one data source classification. </div>"
      }
      if(this.data.DataQuality == "") {
        m = m + "<div style='color:pink;font-size: 20px;'>Please select at least one data quality classification. </div>"
      }
      if(this.data.FlexibilityOfUsage == "") {
        m = m + "<div style='color:pink;font-size: 20px;'>Please select at least one flexibility of usage classification. </div>"
      }
      if(this.data.EaseOfUsage == "") {
        m = m + "<div style='color:pink;font-size: 20px;'>Please select at least one ease of usage classification. </div>"
      }
      if(this.data.Speciality == "") {
        m = m + "<div style='color:pink;font-size: 20px;'>Please select at least one speciality classification. </div>"
      }
      // if(this.data.Email == "" || this.data.Email == null || this.data.Email == undefined) {
      //   m = m + "<div style='color:pink;font-size: 20px;'>Please enter a valid email</div>"
      // }
      // else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.data.Email)) {
      //   m = m + "<div style='color:pink;font-size: 20px;'>Email entered is not valid</div>"
      // }
      if(m != "") {        
        this.setState({message: m, status:"<div style='color:red;font-size: 30px;'>Error</div>"});
        return false;
      } else {
        this.setState({message: "", status: ""});
        return true;
      }
    }
    
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    extendSession() {
      var st = this.getCookie("sessionToken");
      const data = {
        "sessionToken": st
      };
      console.log("Submitting....");
      fetch("https://kcfylcf044.execute-api.us-east-1.amazonaws.com/prod/login", {
                  method: "PUT", headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
      }).then((response) => {
          console.log("In then(response.status) " + response.status);
          if (!response.ok) {
            console.log("Error occured " + response.statusText);
          }
          return response.json();
        }
      ).then((resp) => {
        console.log("In then(resp) status " + resp.status);
        console.log("In then(resp) message " + resp.message);
        if(resp.status !== 'Error') {
          console.log("resp.sessionExpiryTime " + resp.sessionExpiryTime);
          document.cookie = "sessionToken=" + st  + "; expires=" + resp.sessionExpiryTime + "; path=/";
        } else {
          console.log("In then(resp) status " + resp.status);
          console.log("In then(resp) message " + resp.message);
        }
      }).catch((err) => {
            console.log("In catch(err.message) " + err.message);
          }
      );
    }


    handleSubmit(event) {
      this.extendSession();
      event.preventDefault();
      this.data = {
        "Demographics":this.Demographics.current.checked,
        "Diagnoses":this.Diagnoses.current.checked,
        "Labs":this.Labs.current.checked,
        "Procedures":this.Procedures.current.checked,
        "Meds":this.Meds.current.checked,
        "Observations":this.Observations.current.checked,
        "Assessments":this.Assessments.current.checked,
        "Notes":this.Notes.current.checked,
        "Claims":this.Claims.current.checked,
        "EHR":this.EHR.current.checked,
        "Images":this.Images.current.checked,
        "Genomics":this.Genomics.current.checked,        
        "DataQuality":this.calcDataQuality(
            this.Raw.current.checked,
            this.Curated.current.checked,
            this.CuratedAndHumanValidated.current.checked),
        "FlexibilityOfUsage":this.calcFlexibilityOfUsage(
            this.BespokeReport.current.checked,
            this.ConfigurableDashboard.current.checked,
            this.DaaS.current.checked),
        "EaseOfUsage":this.calcEaseOfUsage(
            this.Unharmonized.current.checked,
            this.HarmonizedToControlledVocabularies.current.checked,
            this.HarmonizedToCanonicalOntologyndHumanValidated.current.checked),
        "Speciality":this.calcSpeciality(
            this.Oncology.current.checked,
            this.Autoimmune.current.checked,
            this.Cardiovascular.current.checked,
            this.InfectiousDisease.current.checked,
            this.Endocrinology.current.checked,
            this.Neurology.current.checked,
            this.Ophthalmology.current.checked,
            this.Psychiatry.current.checked,
            this.Respiratory.current.checked,
            this.Gastrointestinal.current.checked,
            this.Nephrology.current.checked,
            this.Reproductive.current.checked,
            this.Dermatology.current.checked,
            this.Orthopedics.current.checked,
            this.Urology.current.checked,
            this.Genetic.current.checked,
            this.Audiology.current.checked,
            this.SleepDisorders.current.checked),
        "sessionToken":this.getCookie("sessionToken")
      }
      if(this.isValid(this.data)) {
        console.log("Submitting....");
        this.setState({message: "", status:""});
        var resp = "";
        //fetch("https://swt75hnxzqbe6woie6l7qayrxe0nbwcs.lambda-url.us-east-1.on.aws/", {
        fetch("https://uqf4ffjshyq2n3u4net6yyb4je0nopma.lambda-url.us-east-1.on.aws/", {
        method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.data),
          })
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .then((resp) => {
                this.setState({message: resp.message, status: resp.status, result: resp.result});
            })
            .catch((err) => {
                this.setState({message: "<div style='color:pink;font-size: 20px;'>Failed to connect to server</div>", 
                    status:"<div style='color:red;font-size: 30px;'>Error</div>"});
            });
      }
    }



 
  render() {
    if(this.state.status == "success") {
      return (
        <>
        <div>
            <p align="CENTER" style={{marginBottom: "0.04in", lineHeight: "100%", pageBreakInside: "avoid", widows: "2", orphans: "2", pageBreakAfter: "avoid"}}><a name="_tr1ihrjno33k"></a>
<font color="#000000"><font size="6" style={{fontSize: "26pt"}}><b>Kaliper</b></font></font></p>
<p>&nbsp;</p>
<p style={{marginBottom: "0in"}}><br/>
    <table width="25%"  align="center" border="1" style={{"minWidth":"400px", borderColor:"red"}}>
        <tbody>
            <tr>
                <td align="center">
                    <p style={{marginBottom: "0in", fontSize: "30pt"}}>Here's the estimated per patient value of your data-set:</p>
                </td>
            </tr>
            <tr>
                <td align="center" border="1" style={{borderColor:"red", fontSize: "40pt"}}>
                    <span><b>{this.state.result} USD</b></span>
                </td>
            </tr>
        </tbody>
    </table>
</p>
<p style={{marginBottom: "0in"}}>Disclaimer: Permutate Health LLC can
not predict and does not guarantee a particular success or result by
usage of any of its products or solutions.</p>        
<p style={{marginBottom: "0in"}}><br/>
</p>

<p style={{marginBottom: "0in"}}>What Kaliper does:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Estimates a per patient value of a
	real world data-set based on certain factors that inform the demand
	for said data. These factors change over time as real world data
	sources become more available and mature.</p></li>
	<li><p style={{marginBottom: "0in"}}>Provides directionality for value
	based pricing of data products and deliverables.</p></li>
	<li><p style={{marginBottom: "0in"}}>Allows for benchmarking of what an
	end user may be willing to pay for the data over time and across
	buyers.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>What Kaliper doesn&rsquo;t do:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Estimate the value of data assets
	for the purpose of company evaluations.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Who should use Kaliper:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Any digital health company that is
	commercializing secondary uses of real world data.<sup>Real world
	data is data that is routinely captured in the course of clinical
	care within systems of record like Electronic Health Record, and
	ancillary systems like Lab Information Management System, Radiology
	Information Management System, Billing/Revenue Cycle Management
	System, etc.</sup></p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
</div>
        </>
      );
    } else {
      return (
        <form onSubmit={this.handleSubmit} action={""} method="POST">        
        <table width="65%"  align="center">
          <tr>
            <td align="left" >
              <p><b><div style={{color:"pink",fontSize: "20px"}}> {this.state.status}</div> <br/> <div style={{color:"pink",fontSize: "20px"}}> {this.state.message}</div></b></p>
              </td>
          </tr>
          <tr>
            <td align="center" >
              <h1 style={{"backgroundColor":"Gray", "align":"center"}}>Kaliper</h1>
            </td>
          </tr>
          <tr>
            <td align="left" >
              <h2 style={{"backgroundColor":"LightGray", "align":"left"}}><u>Data Elements</u> </h2> 
            </td>
          </tr>
          </table>
          <table width="65%"  align="center">
            <tr>
              <td  align="left" width="12%">
                    <span>
                      <input type="checkbox" name="Demographics"  ref={this.Demographics}/> Demographics
                    </span>
              </td>
              <td  align="left" width="24%">
                    <span>
                      <input type="checkbox" name="Diagnoses" ref={this.Diagnoses}/> Diagnoses
                    </span>
              </td>
              <td  align="left" width="42%">
                    <span>
                      <input type="checkbox" name="Labs" ref={this.Labs}/> Labs
                    </span>
              </td>
              <td  align="left" width="22%">
                    <span>
                      <input type="checkbox" name="Procedures" ref={this.Procedures}/> Procedures
                    </span>
              </td>
            </tr>
            <tr>
              <td  align="left" width="12%">
                    <span>
                      <input type="checkbox" name="Meds" ref={this.Meds}/> Meds
                    </span>
              </td>
              <td  align="left" width="24%">
                    <span>
                      <input type="checkbox" name="Observations" ref={this.Observations}/> In office observations - e.g. Vitals
                    </span>
              </td>
              <td  align="left" width="42%">
                    <span>
                      <input type="checkbox" name="Assessments" ref={this.Assessments}/> Assessments - Condition specific tests and outcomes(e.g. ECOG)
                    </span>
              </td>
              <td  align="left" width="22%">
                    <span>
                      <input type="checkbox" name="Notes" ref={this.Notes}/> Doctor's Notes and Inferences
                    </span>
              </td>
            </tr>
          </table>
          <table width="65%"  align="center">
          <tr>
            <td  colspan="4"  align="left" >
              <h2 style={{"backgroundColor":"LightGray", "align":"left"}}><u>Data Source</u>  </h2> 
            </td>
          </tr>
          <tr>
            <td  align="left" width="25%">
                <input type="checkbox" name="Claims"  ref={this.Claims}/> Claims
            </td>
            <td  align="left" width="25%">

                <input type="checkbox" name="EHR" ref={this.EHR}/> EHR
                </td>
            <td  align="left" width="25%">
                
                <input type="checkbox" name="Images" ref={this.Images}/> Images 
                </td>
            <td  align="left" width="25%">
                <input type="checkbox" name="Genomics" ref={this.Genomics}/> Genomics
            </td>
          </tr>
          </table>
          <table width="65%"  align="center">
          <tr>
            <td  colspan="4"  align="left" >
              <h2 style={{"background-color":"LightGray", "align":"left"}}><u>Data Quality</u> </h2> 
            </td>
          </tr>
          <tr>
            <td  align="left" width="25%">

                      <input type="radio" id="Raw" name="DataQuality" value="Raw" ref={this.Raw}/>
                        <label for="Raw">Raw</label>
                      </td>
            <td  align="left" width="25%">

                        <input type="radio" id="Curated" name="DataQuality" value="Curated" ref={this.Curated}/>
                        <label for="Curated">Curated</label>
                      </td>
            <td  align="left" colspan="2">
                        <input type="radio" id="CuratedAndHumanValidated" name="DataQuality" value="CuratedAndHumanValidated" ref={this.CuratedAndHumanValidated}/>
                        <label for="CuratedAndHumanValidated">Curated And Human Validated</label>
            </td>
          </tr>
          </table>
          <table width="65%"  align="center">
          <tr>
            <td  colspan="3"  align="left" >
              <h2 style={{"backgroundColor":"LightGray", "align":"left"}}><u>Flexibility Of Usage</u> </h2> 
            </td>
          </tr>
          <tr>
            <td  align="left" width="25%">

                      <input type="radio" id="BespokeReport" name="EaseOfUsage" value="BespokeReport" ref={this.BespokeReport}/>
                        <label for="BespokeReport">Bespoke Report</label>
                      </td>
            <td  align="left" width="25%">

                        <input type="radio" id="ConfigurableDashboard" name="EaseOfUsage" value="ConfigurableDashboard" ref={this.ConfigurableDashboard}/>
                        <label for="ConfigurableDashboard">Configurable Dashboard</label>
                      </td>
            <td  align="left" colspan="2">
                        <input type="radio" id="DaaS" name="EaseOfUsage" value="DaaS" ref={this.DaaS}/>
                        <label for="DaaS">DaaS</label>
                      </td>
          </tr>
          </table>
          <table width="65%"  align="center">
          <tr>
            <td  colspan="3"  align="left" >
              <h2 style={{"background-color":"LightGray", "align":"left"}}><u>Ease of Usage</u> </h2> 
            </td>
          </tr>
          <tr>
            <td  align="left" width="25%">
                      <input type="radio" id="Unharmonized" name="FlexibilityOfUsage" value="Unharmonized" ref={this.Unharmonized}/>
                        <label for="Unharmonized">Unharmonized</label>
                      </td>
            <td  align="left" width="40%">

                        <input type="radio" id="HarmonizedToControlledVocabularies" name="FlexibilityOfUsage" value="HarmonizedToControlledVocabularies" ref={this.HarmonizedToControlledVocabularies}/>
                        <label for="HarmonizedToControlledVocabularies">Harmonized To Controlled Vocabularies</label>
                      </td>
            <td  align="left" colspan="2">
                        <input type="radio" id="HarmonizedToCanonicalOntologyndHumanValidated" name="FlexibilityOfUsage" value="HarmonizedToCanonicalOntologyndHumanValidated" ref={this.HarmonizedToCanonicalOntologyndHumanValidated}/>
                        <label for="HarmonizedToCanonicalOntologyndHumanValidated">Harmonized to Canonical Ontology</label>
                      </td>
            </tr>
        </table>
        <table width="65%"  align="center">
        <tr>
            <td  colspan="5"  align="left" >
              <h2 style={{"backgroundColor":"LightGray", "align":"left"}}><u>Speciality</u> </h2> 
            </td>
          </tr>
          <tr>
            <td  align="left" width="20%">

                      <input type="radio" id="Oncology" name="Speciality" value="Oncology" ref={this.Oncology}/>
                      <label for="Oncology">Oncology</label>
                      </td>
                      <td  align="left" width="20%">


                      <input type="radio" id="Autoimmune" name="Speciality" value="Autoimmune" ref={this.Autoimmune}/>
                      <label for="Autoimmune">Autoimmune</label>
                      </td>
                      <td  align="left" width="20%">
                      <input type="radio" id="Cardiovascular" name="Speciality" value="Cardiovascular" ref={this.Cardiovascular}/>
                      <label for="Cardiovascular">Cardiovascular</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="InfectiousDisease" name="Speciality" value="InfectiousDisease" ref={this.InfectiousDisease}/>
                      <label for="InfectiousDisease">Infectious Disease</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Endocrinology" name="Speciality" value="Endocrinology" ref={this.Endocrinology}/>
                      <label for="Endocrinology">Endocrinology</label>
                      </td>
          </tr>
          <tr>
                      <td  align="left" width="20%">

                      <input type="radio" id="Neurology" name="Speciality" value="Neurology" ref={this.Neurology}/>
                      <label for="Neurology">Neurology</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Ophthalmology" name="Speciality" value="Ophthalmology" ref={this.Ophthalmology}/>
                      <label for="Ophthalmology">Ophthalmology</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Psychiatry" name="Speciality" value="Psychiatry" ref={this.Psychiatry}/>
                      <label for="Psychiatry">Psychiatry</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Respiratory" name="Speciality" value="Respiratory" ref={this.Respiratory}/>
                      <label for="Respiratory">Respiratory</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Gastrointestinal" name="Speciality" value="Gastrointestinal" ref={this.Gastrointestinal}/>
                      <label for="Gastrointestinal">Gastrointestinal</label>
                      </td>
          </tr>
          <tr>
                      <td  align="left" width="20%">

                      <input type="radio" id="Nephrology" name="Speciality" value="Nephrology" ref={this.Nephrology}/>
                      <label for="Nephrology">Nephrology</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Reproductive" name="Speciality" value="Reproductive" ref={this.Reproductive}/>
                      <label for="Reproductive">Reproductive</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Dermatology" name="Speciality" value="Dermatology" ref={this.Dermatology}/>
                      <label for="Dermatology">Dermatology</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Orthopedics" name="Speciality" value="Orthopedics" ref={this.Orthopedics}/>
                      <label for="Orthopedics">Orthopedics</label>
                      </td>
                      <td  align="left" width="20%">

                      <input type="radio" id="Urology" name="Speciality" value="Urology" ref={this.Urology}/>
                      <label for="Urology">Urology</label>
                      </td>
            </tr>
            <tr>
              <td  align="left" width="20%">

                      <input type="radio" id="Genetic" name="Speciality" value="Genetic" ref={this.Genetic}/>
                      <label for="Genetic">Genetic</label>
              </td>
              <td  align="left" width="20%">

                      <input type="radio" id="Audiology" name="Speciality" value="Audiology" ref={this.Audiology}/>
                      <label for="Audiology">Audiology</label>
                      </td>
                      <td  align="left" colspan="3">

                      <input type="radio" id="SleepDisorders" name="Speciality" value="SleepDisorders" ref={this.SleepDisorders}/>
                      <label for="SleepDisorders">Sleep disorders</label>
                      </td>
            </tr>

            <tr>
            <td  colspan="5"  align="left" >
              <h2 style={{"backgroundColor":"LightGray", "align":"left"}}>&nbsp;</h2> 
            </td>
          </tr>
            <tr>
              <td align="center" width="100%" colspan="5">
                    <input type="submit" value="Submit" style={{border: "0", lineHeight: "2.5", padding: "0 20px",fontSize: "1rem",textAlign: "center",color: "#fff",textShadow: "1px 1px 1px #000",borderRadius: "10px",backgroundColor: "rgba(220, 0, 0, 1)",backgroundImage: "linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0))",boxShadow: "inset 2px 2px 3px rgba(255, 255, 255, 0.6), inset -2px -2px 3px rgba(0, 0, 0, 0.6)"}} />
              </td>
            </tr>
        </table>
        </form>
      );
    }
  }
}

export default KaliperApp;
import './App.css';
import Content from './Content.js';
import { useState } from 'react';
import Section from './Section.js';
import MenuBar from './MenuBar.js';
import * as React from 'react';
import { useEffect } from 'react';


function App() {
  const [currentPage, setCurrentPage] = useState('Home');
  const [loggedIn, setLoggedIn] = useState('false');
  const [consentState, setConsentState] = useState('false');
  const [prevPage, setPrevPage] = useState('Home');
  const [initialMessage, setInitialMessage] = useState('');
  const [initialEmail, setInitialEmail] = useState('');
  
  const loc = require("./ph3.png");
  const homeimg = require("./home.png");

  const handleCurrentPageChange = (page) => {
    document.cookie = "currentPage="+page+";";
    setCurrentPage(page);
  };

  const handleLoggedInChange = (loggedInStatus) => {
    setLoggedIn(loggedInStatus);
  };

  const handlePrevPageChange = (page) => {
    document.cookie = "prevPage="+page+";";
    setPrevPage(page);
  };

  const handleInitialMessageChange = (msg) => {
    setInitialMessage(msg);
  };

  const handleInitialEmailChange = (email) => {
    setInitialEmail(email);
  };

  const handlePoliciesClick = (event) => {
    document.cookie = "prevPage="+currentPage+";";
    document.cookie = "currentPage=Policies;";
    setPrevPage(currentPage);
    setCurrentPage('Policies');
  };

  const handleGoToKaliperClick = (event) => {
    if(loggedIn === 'false') {
      document.cookie = "prevPage=KaliperApp;";
      document.cookie = "currentPage=LoginForm;";
      setPrevPage('KaliperApp');
      setCurrentPage('LoginForm');
    } else {
      document.cookie = "prevPage="+currentPage+";";
      document.cookie = "currentPage=KaliperApp;";
      setPrevPage(currentPage);
      setCurrentPage('KaliperApp');
    }
  }

  useEffect(() => {
    var st = getCookie("sessionToken");
    var cp = getCookie("currentPage");
    var pp = getCookie("prevPage");
    if(st != null && st != "" && st != undefined) {
      if(loggedIn === "false") {
         setLoggedIn("true");
       }
    } else {
       if(loggedIn === "true") {
          setLoggedIn("false");
          if(currentPage == "KaliperApp") {
            alert("Session Time out");
            setCurrentPage("LoginForm");
            document.cookie = "currentPage=LoginForm;";
          }
       }
    }
    if(cp != null && cp !== "" && cp !== undefined) {
      setCurrentPage(getCookie("currentPage"));
    }
    if(pp != null && pp !== "" && pp !== undefined) {
      setPrevPage(getCookie("prevPage"));
    }
  });

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  return (
    <>
      <Section isloggedIn={loggedIn} currentPage={currentPage} prevPage={prevPage}>
        <div style={{textAlign: "center",}}>
          <div className="main-heading" style={{display: "none",}}>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>
              <img src={loc} alt="Permutate Logo"></img>
            </div>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>
                <font>
                  Permutate Health
                </font>
            </div>
          </div>
          <p>&nbsp;</p>

          <MenuBar handleCurrentPageChange={handleCurrentPageChange} handlePrevPageChange={handlePrevPageChange}  handleLoggedInChange={handleLoggedInChange} handleInitialMessageChange={handleInitialMessageChange} />

        </div>
        <div className='content'>
        <Content page={currentPage} handleCurrentPageChange={handleCurrentPageChange} handleLoggedInChange={handleLoggedInChange} handleInitialMessageChange={handleInitialMessageChange} 
              initialMessage={initialMessage} handleInitialEmailChange={handleInitialEmailChange} initialEmail={initialEmail} />
            <div className={currentPage==='Kaliper'?'go-to-kaliper-btn':'hide-go-to-kaliper-btn'}> <button onClick={handleGoToKaliperClick}>Go To Kaliper</button></div><br/><br/>
        </div>

  
      </Section>
      <div>
        <div className={consentState==='false'?'cookie-consent-bar':'hide-cookie-consent-bar'}>

          <span className="cookie-text">
              We use cookies to ensure you have the best browsing experience on our website. 
              By using our site, you acknowledge that you have read and understood our &nbsp;
              <a onClick={handlePoliciesClick} style={{cursor: "pointer"}}>
                <u>Cookie Policy</u></a> &amp;&nbsp;
              <a onClick={handlePoliciesClick} style={{cursor: "pointer"}}><u>Privacy Policy</u></a>
          </span>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <button className="consent-btn" onClick={()=>setConsentState('true')}>Got It !</button>
        </div>
      </div>
   </>
  );
}

export default App;


  

import './App.css';
import './VerifyEmail.css';
import { AppContext } from './AppContext.js';
import { useContext } from 'react';
import { useState } from 'react';
import { useRef } from "react";


function VerifyEmail({handleCurrentPageChange, handleLoggedInChange, handleInitialMessageChange, initialMessage, initialEmail}) {
    const appContext = useContext(AppContext);

    const Email = useRef("Email");
    const VerificationCode = useRef("VerificationCode");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();

  
    function validate(data) {
        var m = "";
        if(data.Email === "" || data.Email === null || data.Email === undefined) {
            m = m + "<div style='color:pink;font-size: 20px;text-align:center;'>Please enter the email provided during sign up.</div>"
        }
        if(data.VerificationCode === "" || data.VerificationCode === null || data.VerificationCode === undefined) {
          m = m + "<div style='color:pink;font-size: 20px;text-align:center;'>Please enter valid verification code</div>"
        }
        return m;
      }
      
      const handleVerificationCodeSubmit = (event) => {
        event.preventDefault();
        const data = {
            "Email":Email.current.value,
            "VerificationCode":VerificationCode.current.value,
        }
      
        const msg = validate(data);
        if(msg !== "") {
            setMessage(msg);
            setStatus("<div style='color:red;font-size: 20px;text-align:center;'>Error</div>");
            VerificationCode.current.value = "";
        } else {
      //            setMessage(msg);
      //            setStatus("<div style='color:red;font-size: 30px;'>Error</div>");
          console.log("Submitting....");
          setMessage("");
          setStatus("");
      
          //this.setState({message: "", status:""});
      //    var resp = "";
          //fetch("https://swt75hnxzqbe6woie6l7qayrxe0nbwcs.lambda-url.us-east-1.on.aws/", {
          //fetch("https://uqf4ffjshyq2n3u4net6yyb4je0nopma.lambda-url.us-east-1.on.aws/", {
//           fetch("http://localhost:3000/api/verifyemail", {
        fetch("https://kcfylcf044.execute-api.us-east-1.amazonaws.com/prod/profile", {
                method: "PUT", headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                body: JSON.stringify(data),
              }
          ).then((response) => {
              console.log("In then(response.status) " + response.status);
              if (!response.ok) {
                console.log("Error occured " + response.statusText);
              }
              return response.json();
            }
          ).then((resp) => {
                console.log("In then(resp) status " + resp.status);
                console.log("In then(resp) message " + resp.message);
                if(resp.status !== 'Error') {
                  VerificationCode.current.value = "";
                  setMessage("");
                  setStatus("");
                  //handleLoggedInChange('true');
                  handleInitialMessageChange('Email verification successful. Please login using the email and password provided during sign up.');
                  handleCurrentPageChange('LoginAfterSignup');
                } else {
                    Email.current.value = "";
                    VerificationCode.current.value = "";
                    setMessage("<div style='color:red;font-size: 20px;text-align:center;'>" + resp.message + "</div>");
                    setStatus("<div style='color:red;font-size: 20px;text-align:center;'>" + resp.status + "</div>");
                }
              }
          ).catch((err) => {
                console.log("In catch(err.message) " + err.message);
                setMessage("<div style='color:red;font-size: 20px;'>" + "Unexpected Problem. Please try later" + "</div>");
                setStatus("<div style='color:red;font-size: 20px;'>Error</div>");
                VerificationCode.current.value = "";
              }
          );
        }
      }
      

    return (

        <div className='verification-form'>

            <form onSubmit={handleVerificationCodeSubmit} action={""} method="POST">
                <table width="25%"  align="center" border="0" style={{"minWidth":"400px"}}>
                    <tbody>
                        <tr>
                            <td align="left" colSpan="2">
                                <span className={message==""?"initial-message":"hide-initial-message"}>{initialMessage}</span>
                                <b><div dangerouslySetInnerHTML={{__html: status}}></div> <br/> <div dangerouslySetInnerHTML={{__html: message}}></div></b>
                            </td>
                        </tr>
                        <tr style={{"backgroundColor":"LightGray","lineHeight":"1%"}}>
                            <td colSpan="2" style={{"backgroundColor":"LightGray"}}>
                                <h2 align="center" style={{"fontSize":"20pt"}}>Verify Email {initialEmail}</h2>
                            </td>
                        </tr>
                        <tr><td></td></tr>
                        <tr><td></td></tr>

                        <tr>
                            <td  align="left" width="40%">
                                <label htmlFor="Email" style={{"fontSize":"10pt", "marginLeft":"10%"}}>Email* : </label>
                            </td>
                            <td  align="left" width="60%">
                                <input size="50" type="text" name="Email" ref={Email} style={{"lineHeight":"15pt", "width":"95%", "fontSize":"10pt"}}  
                                         defaultValue={initialEmail}  />
                            </td>
                        </tr>
 
                        <tr><td></td></tr>
                        <tr><td></td></tr>
                        <tr>
                            <td  align="left" width="40%">
                                <label htmlFor="Verification Code" style={{"fontSize":"10pt", "marginLeft":"10%"}}>Verification Code* : </label>
                            </td>
                            <td  align="left" width="60%">
                                <input size="50" type="password" name="VerificationCode" ref={VerificationCode} style={{"lineHeight":"15pt", "width":"95%", "fontSize":"10pt"}}  />
                            </td>
                        </tr>
                        <tr><td></td></tr>
                        <tr><td></td></tr>
                        <tr>
                            <td align="center" width="100%" colSpan="2">
                                <input type="submit" value="Verify" style={{"border": "0", "lineHeight": "1.5", "padding": "0 20px","fontSize": "1rem","textAlign": "center","color": "#fff","textShadow": "1px 1px 1px #000","borderRadius": "10px","backgroundColor": "rgba(220, 0, 0, 1)","backgroundImage": "linearGradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0))","boxShadow": "inset 2px 2px 3px rgba(255, 255, 255, 0.6), inset -2px -2px 3px rgba(0, 0, 0, 0.6)"}} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
  }
  
  export default VerifyEmail;
  
import './RWDStartups.css';




function RWDStartups() {
    const curve = require("./curve.png");
    const data_strategy = require("./data_strategy3.png");
    const data_prd_development = require("./data_prd_development3.png");
    const data_diligence = require("./data_diligence3.png");
    
    return (
        <>
            <div width="80%" className="content-div">
                <p>
                    <div style={{verticalAlign: "middle",display:"inline-block",textAlign:"left"}}>
                        <p>
                            Services    &gt;    RWD Startups
                        </p>
                        <div style={{verticalAlign: "middle",display:"inline-block"}}>
                            <img src={curve} alt="Img" style={{width:"100px",height:"120px"}} />
                        </div>
                        <div style={{verticalAlign: "middle",display:"inline-block"}}>
                            <font>
                                &nbsp;&nbsp;&nbsp;&nbsp;By leveraging data science, informatics and artificial intelligence, 
                                we can help our clients make informed decisions and drive positive change in the healthcare industry.                    
                            </font>
                        </div>
                    </div>
                </p>
                <p>
                    <div width="33%" style={{verticalAlign: "middle",display:"inline-block"}}>
                        <img src={data_strategy} alt="Data Strategy"></img>
                    </div>
                    <div width="33%" style={{verticalAlign: "middle",display:"inline-block"}}>
                        <img src={data_prd_development} alt="Data Product Development"></img>
                    </div>
                    <div width="33%" style={{verticalAlign: "middle",display:"inline-block"}}>
                        <img src={data_diligence} alt="Data Diligence"></img>
                    </div>
                </p>
        </div>
        </>
    );
  }
  
export default RWDStartups;
  
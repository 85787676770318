

function Kaliper({children}) {
    return (
        <div>
            <p align="CENTER" style={{marginBottom: "0.04in", lineHeight: "100%", pageBreakInside: "avoid", widows: "2", orphans: "2", pageBreakAfter: "avoid"}}><a name="_tr1ihrjno33k"></a>
<font color="#000000"><font size="6" style={{fontSize: "26pt"}}><b>Kaliper</b></font></font></p>
<p>&nbsp;</p>
<p style={{marginBottom: "0in"}}>You can&rsquo;t manage what you can&rsquo;t
measure. At Permutate we believe that measuring the value of your
data-sets and tracking it over time is essential to managing and
going to market with data products. We have developed Permutate
Kaliper, a data value estimator, for the purpose of measuring and
benchmarking the value of real world healthcare data being made
available to the market for secondary uses.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>What Kaliper does:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Estimates a per patient value of a
	real world data-set based on certain factors that inform the demand
	for said data. These factors change over time as real world data
	sources become more available and mature.</p></li>
	<li><p style={{marginBottom: "0in"}}>Provides directionality for value
	based pricing of data products and deliverables.</p></li>
	<li><p style={{marginBottom: "0in"}}>Allows for benchmarking of what an
	end user may be willing to pay for the data over time and across
	buyers.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>What Kaliper doesn&rsquo;t do:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Estimate the value of data assets
	for the purpose of company evaluations.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Who should use Kaliper:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Any digital health company that is
	commercializing secondary uses of real world data.<sup>Real world
	data is data that is routinely captured in the course of clinical
	care within systems of record like Electronic Health Record, and
	ancillary systems like Lab Information Management System, Radiology
	Information Management System, Billing/Revenue Cycle Management
	System, etc.</sup></p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Disclaimer: Permutate Health LLC can
not predict and does not guarantee a particular success or result by
usage of any of its products or solutions.</p>        
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>{children}</p>     

</div>
    );
  }
  
export default Kaliper;

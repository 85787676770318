
function Policies() {
    return (
        <div>


<p align="CENTER" style={{marginBottom: "0.04in", lineHeight: "100%", pageBreakInside: "avoid", widows: "2", orphans: "2", pageBreakAfter: "avoid"}}><a name="_tr1ihrjno33k"></a>
<font color="#000000"><font size="6" style={{fontSize: "26pt"}}><b>Privacy
Policy</b></font></font></p>
<h2 class="western"><a name="__RefHeading__119_1808967355"></a><a name="_kgqi2xnil0xu"></a>
Last Updated: February 12, 2023</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<div id="Table of Contents1" dir="LTR">
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__119_1808967355">Last
	Updated: February 12, 2023	1</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__121_1808967355">Collection
	of your Personal Information	1</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__123_1808967355">Use
	of your Personal Information	1</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__125_1808967355">Sharing
	Information with Third Parties	2</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__127_1808967355">Opt-Out
	of Disclosure of Personal Information to Third Parties	2</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__129_1808967355">Tracking
	User Behavior	3</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__131_1808967355">Automatically
	Collected Information	3</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__133_1808967355">De-identified
	Information 	3</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__135_1808967355">Right
	to Deletion	3</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__137_1808967355">Children
	Under Thirteen	4</a></p>
	<p style={{marginLeft: "0.2in", marginBottom: "0in"}}><a href="#__RefHeading__139_1808967355">Opt-Out
	&amp; Unsubscribe from Third Party Communications	4</a></p>
</div>
<p style={{marginTop: "0.14in", marginBottom: "0.06in", lineHeight: "100%"}}>
<br/><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Protecting your private information is
our priority. This Statement of Privacy applies to Permutate Health
LLC and governs data collection and usage. The website,
<a href="https://www.permutate.health/"><font color="#1155cc"><u>https://www.permutate.health/</u></font></a>
is a Permutate Health LLC website (&ldquo;the Site&rdquo;). By using
the Site, you consent to the policies and practices described in this
Privacy Policy.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__121_1808967355"></a><a name="_dfh8zu1h4sgj"></a>
Collection of your Personal Information</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>We receive and store any information
you knowingly provide to us. For example, you may provide certain
personal information to us when you elect to use our products or
services. These may include: (a) registering for an account; (b)
sending us an email message; (c) submitting your credit card or other
payment information when ordering and purchasing products and
services. To wit, we will use your information for, but not limited
to, communicating with you in relation to services and/or products
you have requested from us. We also may gather additional personal or
non-personal information in the future.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__123_1808967355"></a><a name="_dfq9i2ceu77k"></a>
Use of your Personal Information</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC collects and uses
your personal information to operate and deliver the services you
have requested. 
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC may also use your
personally identifiable information to inform you of other products
or services available from Permutate Health LLC and its affiliates.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__125_1808967355"></a><a name="_logp4llh7j63"></a>
Sharing Information with Third Parties</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC does not sell,
rent or lease its customer lists to third parties. 
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC may, from time to
time, contact you on behalf of external business partners about a
particular offering that may be of interest to you. In those cases,
when authorized, your unique personally identifiable information
(e-mail, name, address, telephone number) is transferred to the third
party. Permutate Health LLC may share data with trusted partners to
help perform statistical analysis, send you email or postal mail,
provide customer support, or arrange for deliveries. All such third
parties are prohibited from using your personal information except to
provide these services to Permutate Health LLC and they are required
to maintain the confidentiality of your information.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC may disclose your
personal information, without notice, if required to do so by law or
in the good faith belief that such action is necessary to: (a)
conform to the edicts of the law or comply with legal process served
on Permutate Health LLC or the Site; (b) protect and defend the
rights or property of Permutate Health LLC; and/or (c) act under
exigent circumstances to protect the personal safety of users of the
Site, or the public.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__127_1808967355"></a><a name="_u07slpoz4smx"></a>
Opt-Out of Disclosure of Personal Information to Third Parties</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>In connection with any personal
information we may disclose to a third party for a business purpose,
you have the right to know:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>The categories of personal
	information that we disclosed about you for a business purpose.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>You have the right under the California
Consumer Privacy Act of 2018 (CPA) and certain other privacy and data
protection laws, as applicable, to opt-out of the disclosure of your
personal information. If you exercise your right to opt-out of the
disclosure of your personal information, we will refrain from
disclosing your personal information, unless you subsequently provide
express authorization for the disclosure of your personal
information. To opt-out of the disclosure of your personal
information, contact us here: contactus@permutate.health</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__129_1808967355"></a><a name="_omwutn8k9mk5"></a>
Tracking User Behavior</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC may keep track of
the pages our users visit within the Site in order to determine what
Permutate Health LLC services are the most popular. This data is used
to deliver customized content and advertising within the Site to
customers whose behavior indicates that they are interested in a
particular subject area.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__131_1808967355"></a><a name="_gngig4hlnqri"></a>
Automatically Collected Information</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Information about your computer
hardware and software may be automatically collected by Permutate
Health LLC. This information can include: your IP address, location
information, device type, device identifier, browser type, domain
names, access times, referring website addresses and other
statistics. This information is used for the operation of the
service, to maintain quality of the service, and to improve the Site
over time.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__133_1808967355"></a><a name="_dcfmsok1j2j9"></a>
De-identified Information 
</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>We may de-identify your Personal
Information so that you are not identified as an individual, and
provide that information to our partners and business associates.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__135_1808967355"></a><a name="_ku3j0guv4ake"></a>
Right to Deletion</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Subject to certain exceptions set out
below, on receipt of a verifiable request from you, we will:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Delete your personally
	identifiable information from our records; and 
	</p></li>
	<li><p style={{marginBottom: "0in"}}>Direct any service providers to
	delete your personally identifiable information from their records.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Please note that we may not be able to
comply with requests to delete your personal information if it is
necessary to:</p>
<ul>
	<li><p style={{marginBottom: "0in"}}>Complete the transaction for which
	the personal information was collected, fulfill the terms of a
	written warranty or product recall conducted in accordance with
	federal law, provide a good or service requested by you, or
	reasonably anticipated within the context of our ongoing business
	relationship with you, or otherwise perform a contract between you
	and us;</p></li>
	<li><p style={{marginBottom: "0in"}}>Detect security incidents, protect
	against malicious, deceptive, fraudulent, or illegal activity; or
	prosecute those responsible for that activity;</p></li>
	<li><p style={{marginBottom: "0in"}}>Debug to identify and repair
	errors that impair existing intended functionality;</p></li>
	<li><p style={{marginBottom: "0in"}}>Exercise free speech, ensure the
	right of another consumer to exercise his or her right of free
	speech, or exercise another right provided for by law;</p></li>
	<li><p style={{marginBottom: "0in"}}>Comply with the California
	Electronic Communications Privacy Act;</p></li>
	<li><p style={{marginBottom: "0in"}}>Engage in public or peer-reviewed
	scientific, historical, or statistical research in the public
	interest that adheres to all other applicable ethics and privacy
	laws, when our deletion of the information is likely to render
	impossible or seriously impair the achievement of such research,
	provided we have obtained your informed consent;</p></li>
	<li><p style={{marginBottom: "0in"}}>Enable solely internal uses that
	are reasonably aligned with your expectations based on your
	relationship with us;</p></li>
	<li><p style={{marginBottom: "0in"}}>Comply with an existing legal
	obligation; or</p></li>
	<li><p style={{marginBottom: "0in"}}>Otherwise use your personal
	information, internally, in a lawful manner that is compatible with
	the context in which you provided the information.</p></li>
</ul>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__137_1808967355"></a><a name="_aqmiqaqree7p"></a>
Children Under Thirteen</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>Permutate Health LLC does not knowingly
collect personally identifiable information from children under the
age of thirteen. If you are under the age of thirteen, you must ask
your parent or guardian for permission to use this website.</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<h2 class="western"><a name="__RefHeading__139_1808967355"></a><a name="_lnbt50zak87h"></a>
Opt-Out &amp; Unsubscribe from Third Party Communications</h2>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>We respect your privacy and give you an
opportunity to opt-out of receiving announcements of certain
information. Users may opt-out of receiving any or all communications
from third-party partners of Permutate Health LLC by contacting us
here:</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}>contactus@permutate.health</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<p style={{marginBottom: "0in"}}><br/>
</p>
<div type="FOOTER">
	<p align="RIGHT" style={{marginTop: "0.46in", marginBottom: "0in"}}><br/>
	</p>
</div>

</div>
    );
  }
  
export default Policies;

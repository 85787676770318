import Home from './Home.js';
import About from './About.js';
import Policies from './Policies.js';
import Contact from './Contact.js';
import RWDStartups from './RWDStartups.js';
import PatientAdvocacyGroups from './PatientAdvocacyGroups.js';
import Kaliper from './Kaliper.js';
import PatientHealthDataAggregator from './PatientHealthDataAggregator.js';
import KaliperApp from './KaliperApp.js';
import LoginForm from './LoginForm.js';
import SignupForm from './SignupForm.js';
import VerifyEmail from './VerifyEmail.js';



function Content({page, handleCurrentPageChange, handleLoggedInChange, handleInitialMessageChange, initialMessage, handleInitialEmailChange, initialEmail}) {
    if(page==='Home') {
      return (
        <>
          <Home/>
        </>
      );  
    } else if(page==='About') {
      return (
        <>
        <About/>
        </>
      );
    } else if(page==='RWD Startups') {
      return (
        <>
        <RWDStartups/>
        </>
      );  
    } else if(page==='Patient Advocacy Groups') {
      return (
        <>
        <PatientAdvocacyGroups/>
        </>
      );  
    } else if(page==='Kaliper') {
      return (
        <>
        <Kaliper/>
        </>
      );
    } else if(page==='Patient Health Data Aggregator') {
      return (
        <>
        <PatientHealthDataAggregator/>
        </>
      );
    } else if(page==='Contact') {
      return (
        <>
        <Contact/>
        </>
      );
    } else if(page==='Policies') {
      return (
        <>
        <Policies/>
        </>
      );  
    } else if(page==='KaliperApp') {
      return (
        <>
        <KaliperApp/>
        </>
      );  
    } else if(page==='LoginForm') {
      return (
        <>
        <LoginForm handleCurrentPageChange={handleCurrentPageChange} handleLoggedInChange={handleLoggedInChange} initialMessage={''} />
        </>
      );  
    } else if(page==='LoginAfterSignup') {
      return (
        <>
        <LoginForm handleCurrentPageChange={handleCurrentPageChange} handleLoggedInChange={handleLoggedInChange} initialMessage={initialMessage} />
        </>
      );  
    } else if(page==='Signup') {
      return (
        <>
          <SignupForm handleCurrentPageChange={handleCurrentPageChange} handleInitialMessageChange={handleInitialMessageChange} handleInitialEmailChange={handleInitialEmailChange} />
        </>
      );  
    } else if(page==='VerifyEmail') {
      return (
        <>
          <VerifyEmail handleCurrentPageChange={handleCurrentPageChange} handleInitialMessageChange={handleInitialMessageChange} initialMessage={initialMessage} initialEmail={initialEmail}/>
        </>
      );  
    } else if(page==='VerifyEmailAfterSignup') {
      return (
        <>
          <VerifyEmail handleCurrentPageChange={handleCurrentPageChange} handleInitialMessageChange={handleInitialMessageChange} initialMessage={"Please enter verification code sent to the provided email to complete the process."} initialEmail={''} />
        </>
      );  
    } else {
        return (
            <>Error</>
          );    
    }
}
  
export default Content;
  
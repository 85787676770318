
import './Home.css';
import './App.css';


function Home() {
    const homeimg = require("./permutate_home6.png");
    const loc = require("./ph1.png");

    return (
        <div className="main-div">
          <div className="main-heading">
              <div style={{verticalAlign: "middle",display:"inline-block"}}>
                <img src={loc} alt="Permutate Logo" style={{width:"200px",height:"200px"}}></img>
              </div>
              <br/>
              <div style={{verticalAlign: "middle",display:"inline-block"}} className="main-div">
                  <font>
                    Permutate Health
                  </font>
              </div>
          </div>

          <p>&nbsp;</p>
           {/*
            <img src={homeimg} alt="Permutate Image" style={{width:"20%",height:"20%"}}></img>
           */}
        </div>
    );
}
  
export default Home;
  
import './PatientAdvocacyGroups.css';

function PatientAdvocacyGroups() {
    const magnifying_glass = require("./magnifying_glass.png");
    const data_concierge = require("./data_concierge1.png");

    return (
        <div className="content-div">
            <p>
                <div style={{verticalAlign: "middle",display:"inline-block",textAlign:"left", width:"70%"}}>

                    <p style={{textAlign:"left"}}>
                            Services    &gt;    Patient Advocacy Groups
                    </p>

                    <div style={{verticalAlign: "middle",display:"inline-block"}}>
                        <img src={magnifying_glass} alt="Img" style={{width:"75px",height:"75px"}} ></img>
                    </div>
                    &nbsp;
                    &nbsp;
                    <div style={{verticalAlign: "middle",display:"inline-block", width:"90%"}}>
                        <font>
                            By utilizing the patient data transparency provisions in our regulations 
                            we can strengthen the voice of the consumers in an industry that tells us we’re not good enough 
                            to make decisions about our own health                   
                        </font>
                    </div>
                </div>
            </p>
            <p>
                <div width="33%" style={{verticalAlign: "middle",display:"inline-block"}}>
                    <img src={data_concierge} alt="Data Concierge"></img>
                </div>
            </p>
    </div>
);
  }
  
export default PatientAdvocacyGroups;
  


import { AppContext } from './AppContext.js';

function Section({ isloggedIn, currentPage, prevPage, children }) {
  return (
    <section>
      <AppContext.Provider value={{isloggedIn:isloggedIn, currentPage:currentPage, prevPage:prevPage}}>
        {children}
      </AppContext.Provider>
    </section>
  );
}

export default Section;

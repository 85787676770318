import './App.css';
import './LoginForm.css';
import { AppContext } from './AppContext.js';
import { useContext } from 'react';
import { useState } from 'react';
import { useRef } from "react";


function LoginForm({handleCurrentPageChange, handleLoggedInChange, initialMessage}) {
    const appContext = useContext(AppContext);
    const isloggedIn = appContext['isloggedIn'];
    const currentPage = appContext['currentPage'];
    const prevPage = appContext['prevPage'];

    const Email = useRef("Email");
    const Pwd = useRef("Pwd");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [token, setToken] = useState();
  
    function validate(data) {
        var m = "";
        if(data.Email === "" || data.Email === null || data.Email === undefined) {
          m = m + "<div style='color:pink;font-size: 20px;text-align:center;'>Please enter a valid email</div>"
        }
        else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.Email)) {
          console.log()
          m = m + "<div style='color:pink;font-size: 20px;text-align:center;'>Email entered is not valid</div>"
        }
        if(data.Pwd === "" || data.Pwd === null || data.Pwd === undefined) {
          m = m + "<div style='color:pink;font-size: 20px;text-align:center;'>Please enter password</div>"
        }
        return m;
      }
      
      const handleSignInSubmit = (event) => {
        event.preventDefault();
        const data = {
            "Email":Email.current.value,
            "Pwd":Pwd.current.value
        }
      
        const msg = validate(data);
        if(msg !== "") {
            setMessage(msg);
            setStatus("<div style='color:pink;font-size: 20px;text-align:center;'>Error</div>");
            Pwd.current.value = "";
        } else {
      //            setMessage(msg);
      //            setStatus("<div style='color:red;font-size: 30px;'>Error</div>");
          console.log("Submitting....");
          setMessage("");
          setStatus("");
      
          //this.setState({message: "", status:""});
      //    var resp = "";
          //fetch("https://swt75hnxzqbe6woie6l7qayrxe0nbwcs.lambda-url.us-east-1.on.aws/", {
          //fetch("https://uqf4ffjshyq2n3u4net6yyb4je0nopma.lambda-url.us-east-1.on.aws/", {
//           fetch("http://localhost:3000/api/login", {
             fetch("https://kcfylcf044.execute-api.us-east-1.amazonaws.com/prod/login", {
                            method: "POST", headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                body: JSON.stringify(data),
              }
          ).then((response) => {
              console.log("In then(response.status) " + response.status);
              if (!response.ok) {
                console.log("Error occured " + response.statusText);
              }
              return response.json();
            }
          ).then((resp) => {
                console.log("In then(resp) status " + resp.status);
                console.log("In then(resp) message " + resp.message);
                if(resp.status !== 'Error') {
                  Email.current.value = "";
                  Pwd.current.value = "";
                  setMessage("");
                  setStatus("");
                  handleLoggedInChange('true');                  
                  handleCurrentPageChange(prevPage);
                  // const d = new Date();
                  // d.setTime(d.getTime() + (10*60*1000));
                  // let expires = "expires="+ d.toUTCString();
                  document.cookie = "sessionToken=" + resp.sessionToken + "; expires=" + resp.sessionExpiryTime +"; path=/"
                } else {
                  Pwd.current.value = "";
                  setMessage("<div style='color:pink;font-size: 20px;text-align:center;'>" + resp.message + "</div>");
                  setStatus("<div style='color:pink;font-size: 20px;text-align:center;'>" + resp.status + "</div>");
                }
              }
          ).catch((err) => {
                console.log("In catch(err.message) " + err.message);
                setMessage("<div style='color:pink;font-size: 20px;'>" + "Unexpected Problem. Please try later" + "</div>");
                setStatus("<div style='color:pink;font-size: 20px;'>Error</div>");
                Pwd.current.value = "";
              }
          );
        }
      }
      

    return (

        <div className='login-form'>

            <form onSubmit={handleSignInSubmit} action={""} method="POST">
                <table width="25%"  align="center" border="0" style={{"minWidth":"400px"}}>
                    <tbody>
                        <tr>
                            <td align="left" colSpan="2">
                                <span className={message==""?"initial-message":"hide-initial-message"}>{initialMessage}</span>
                                <b><div dangerouslySetInnerHTML={{__html: status}}></div> <br/> <div dangerouslySetInnerHTML={{__html: message}}></div></b>
                            </td>
                        </tr>
                        <tr style={{"backgroundColor":"LightGray","lineHeight":"1%"}}>
                            <td colSpan="2" style={{"backgroundColor":"LightGray"}}>
                                <h2 align="center" style={{"fontSize":"20pt"}}>Login</h2> 
                            </td>
                        </tr>
                        <tr><td colSpan="2"></td></tr>
                        <tr><td colSpan="2"></td></tr>
                        <tr>
                            <td  align="left" width="25%">
                                <label htmlFor="Email" style={{"fontSize":"10pt", "marginLeft":"10%"}}>Email* : </label>
                            </td>
                            <td  align="left" width="75%" >
                                <input type="text" name="Email" ref={Email } style={{"lineHeight":"15pt", "width":"95%", "fontSize":"10pt"}} />
                            </td>
                        </tr>
                        <tr><td colSpan="2"></td></tr>
                        <tr><td colSpan="2"></td></tr>
                        <tr>
                            <td  align="left" width="25%">
                                <label htmlFor="Pwd" style={{"fontSize":"10pt", "marginLeft":"10%"}}>Password* : </label>
                            </td>
                            <td  align="left" width="75%">
                                <input size="50" type="password" name="Pwd" ref={Pwd} style={{"lineHeight":"15pt", "width":"95%", "fontSize":"10pt"}}  />
                            </td>
                        </tr>
                        <tr><td colSpan="2"></td></tr>
                        <tr><td colSpan="2"></td></tr>
                        <tr>
                            <td align="center" width="100%" colSpan="2">
                                <input type="submit" value="Login" style={{"border": "0", "lineHeight": "1.5", "padding": "0 20px","fontSize": "1rem","textAlign": "center","color": "#fff","textShadow": "1px 1px 1px #000","borderRadius": "10px","backgroundColor": "rgba(220, 0, 0, 1)","backgroundImage": "linearGradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0))","boxShadow": "inset 2px 2px 3px rgba(255, 255, 255, 0.6), inset -2px -2px 3px rgba(0, 0, 0, 0.6)"}} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
  }
  
  export default LoginForm;
  
import './About.css';


function About() {
    return (
      <div>
        <p className="para-text">
          Permutate Health is a healthcare Real World Data consulting organization.
          At Permutate Health we are passionate about transforming healthcare through data. 
          We believe that all data collected in the process of healthcare access, delivery, and research, should be democratized for the sake of everyday people and consumers, who stand 
          to benefit from the right care at the right time - proactively planned, well researched and available as soon as pragmatically justified. 
        </p>
        <p className="para-text">
        The rewards of data sharing outweigh its risks as long as everyone who is granted the power to handle patient data is serious about their responsibility 
        of preventing their misuse, and acting in the best interest of the individual about whom the data are. 
        Real world data are defined as routinely collected data during the normal process of an individual’s interaction with the health system. 
        These data live in systems such as electronic health records, claims, pharmacies, ancillary information systems like labs and radiology, patient apps, wearables, etc. 
        </p>
        <p className="para-text">
        Permutate specializes in solutions that unlock the value of these real world data and allow data-driven decisions in the healthcare ecosystem. 
        Data driven decisions in healthcare can have lasting impacts on our collective quality of life. 
        Imagine better patient outcomes, better treatment efficacy, lower cost of care, equitable access, efficient research, and availability of abundant treatments faster. 
        Our journey towards our shared goals as a healthcare industry starts with transparency into our current baseline of care and an understanding of the greater possibilities 
        based on  the scattered successes we can learn from.        
        </p>
      </div>
    );
  }
  
export default About;
  
import { AppContext } from './AppContext.js';
import { useContext } from 'react';
import { useState } from 'react';
import './App.css';
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
const homeimg = require("./home.png");


function MenuBar1({handleCurrentPageChange, handlePrevPageChange, handleLoggedInChange, handleInitialMessageChange}) {
    const appContext = useContext(AppContext);
    const isloggedIn = appContext['isloggedIn']
    const currentPage = appContext['currentPage']
    const prevPage = appContext['prevPage']
    const [productsAnchorEl, setProductsAnchorEl] = useState(null);
    const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
    const [signupAnchorEl, setSignupAnchorEl] = useState(null);
  
    const handleSignupFormClose = () => {
      setSignupAnchorEl(null);
    };
  
    const handleSignupClose = () => {
      setSignupAnchorEl(null);
      handleCurrentPageChange('Signup');
    };
  
    const handleVerifyEmailClose = () => {
      setSignupAnchorEl(null);
      handleCurrentPageChange('VerifyEmailAfterSignup');
    };

    const handleServicesClose = () => {
      setServicesAnchorEl(null);
    };
  
    const handleServicesRWDStartupsClose = () => {
      setServicesAnchorEl(null);
      handleCurrentPageChange('RWD Startups');
    };
  
    const handleServicesPatientAdvocacyGroupsClose = () => {
      setServicesAnchorEl(null);
      handleCurrentPageChange('Patient Advocacy Groups');
    };
    
  
    const handleProductsKaliperClose = () => {
      setProductsAnchorEl(null);
      handleCurrentPageChange('Kaliper');
    };
  
    const handleProductsPatientHealthDataAggregatorClose = () => {
      setProductsAnchorEl(null);
      handleCurrentPageChange('Patient Health Data Aggregator');
    };
  
    const handleProductsClose = () => {
      setProductsAnchorEl(null);
    };
    
    const handleHomeClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      handleCurrentPageChange('Home');
    };
  
    const handleAboutClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      handleCurrentPageChange('About');
    };
  
    const handleServicesClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      setServicesAnchorEl(event.currentTarget);
    };
  
    const handleProductsClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      setProductsAnchorEl(event.currentTarget);
    };
    const handleSignupClick = (event) => {
      setSignupAnchorEl(event.currentTarget);
    };
  
    const handleContactClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      handleCurrentPageChange('Contact');
    };
  
    const handlePoliciesClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      handleCurrentPageChange('Policies');
    };
  
    const handleGoToKaliperClick = (event) => {
      if(isloggedIn === 'false') {
        handlePrevPageChange('KaliperApp');
        handleCurrentPageChange('LoginForm');
      } else {
        extendSession();
        handleCurrentPageChange('KaliperApp');
      }
    }
      // const handleSignupClick = (event) => {
      //   handleCurrentPageChange('Signup');
      // }
    const handleSigninClick = (event) => {
        handlePrevPageChange(currentPage);
        handleInitialMessageChange('');
        handleCurrentPageChange('LoginForm');
    }
    const handleLogoutClick = (event) => {
        logoutSession();
    }
    const handleProfileClick = (event) => {
      if(isloggedIn !== 'false') {
        extendSession();
      }
      handleCurrentPageChange('Profile');
    }

    function extendSession() {
      var st = getCookie("sessionToken");
      const data = {
        "sessionToken": st
      };
      console.log("Submitting....");
      fetch("https://kcfylcf044.execute-api.us-east-1.amazonaws.com/prod/login", {
                  method: "PUT", headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
      }).then((response) => {
          console.log("In then(response.status) " + response.status);
          if (!response.ok) {
            console.log("Error occured " + response.statusText);
          }
          return response.json();
        }
      ).then((resp) => {
        console.log("In then(resp) status " + resp.status);
        console.log("In then(resp) message " + resp.message);
        if(resp.status !== 'Error') {
          console.log("resp.sessionExpiryTime " + resp.sessionExpiryTime);
          document.cookie = "sessionToken=" + st  + "; expires=" + resp.sessionExpiryTime + "; path=/";
        } else {
          console.log("In then(resp) status " + resp.status);
          console.log("In then(resp) message " + resp.message);
        }
      }).catch((err) => {
            console.log("In catch(err.message) " + err.message);
          }
      );
    }


    function logoutSession() {
      var st = getCookie("sessionToken");
      const data = {
        "sessionToken": st,
        "logoutFlag": "true"
      };
      console.log("Submitting....");
      fetch("https://kcfylcf044.execute-api.us-east-1.amazonaws.com/prod/login", {
                  method: "PUT", headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
      }).then((response) => {
          console.log("In then(response.status) " + response.status);
          if (!response.ok) {
            console.log("Error occured " + response.statusText);
          }
          return response.json();
        }
      ).then((resp) => {
        console.log("In then(resp) status " + resp.status);
        console.log("In then(resp) message " + resp.message);
        if(resp.status !== 'Error') {
          document.cookie = "sessionToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          handleCurrentPageChange('Home');
          handleLoggedInChange('false');  
//          document.cookie = "sessionToken=" + st  + "; expires=" + resp.sessionExpiryTime +"; path=/";
        } else {
          console.log("In then(resp) status " + resp.status);
          console.log("In then(resp) message " + resp.message);
        }
      }).catch((err) => {
            console.log("In catch(err.message) " + err.message);
          }
      );
    }


    function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  

    return (
        <div style={{verticalAlign: "middle",display:"inline-block"}}>       
        <div style={{verticalAlign: "middle",display:"inline-block"}}>       
            <Button
                aria-controls="simple-menu"
                aria-haspopup="false"
                onClick={handleHomeClick}
            >
                <span className={currentPage==='Home'?'menu-btn-selected':''}>
                <div style={{verticalAlign: "middle",display:"inline-block"}}><img src={homeimg} alt="home" style={{width:"30px",height:"30px"}}/></div>
                    <div style={{verticalAlign: "middle",display:"inline-block"}}>
                    &nbsp;
                    <font className={currentPage==='Home'?'menu-btn-selected':''}>
                        Home
                    </font>
                    </div>
                </span>
            </Button>
            </div>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>       

            <Button
                aria-controls="simple-menu"
                aria-haspopup="false"
                onClick={handleAboutClick}
            >
                <span className={currentPage==='About'?'menu-btn-selected':''}>About</span>
            </Button>
            </div>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>       
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleServicesClick}
            >
                <span className={currentPage==='RWD Startups'||currentPage==='Patient Advocacy Groups'?'menu-btn-selected':''}>Services</span>
            </Button>
            <Menu
                keepMounted
                anchorEl={servicesAnchorEl}
                onClose={handleServicesClose}
                open={Boolean(servicesAnchorEl)}
            >
                <MenuItem onClick={handleServicesRWDStartupsClose}>RWD Startups</MenuItem>
                <MenuItem onClick={handleServicesPatientAdvocacyGroupsClose}>Patient Advocacy Groups</MenuItem>
            </Menu>
            </div>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>       

            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleProductsClick}
            >
                <span className={currentPage==='Kaliper'||currentPage==='Patient Health Data Aggregator'?'menu-btn-selected':''}>Products</span>          
            </Button>
            <Menu
                keepMounted
                anchorEl={productsAnchorEl}
                onClose={handleProductsClose}
                open={Boolean(productsAnchorEl)}
            >
                <MenuItem onClick={handleProductsKaliperClose}>Kaliper</MenuItem>
                <MenuItem onClick={handleProductsPatientHealthDataAggregatorClose}>Patient Health Data Aggregator</MenuItem>
            </Menu>
            </div>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>       

            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleContactClick}
            >
                <span className={currentPage==='Contact'?'menu-btn-selected':''}>Contact</span>
                
            </Button>
            </div>
            <div style={{verticalAlign: "middle",display:"inline-block"}}>       
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handlePoliciesClick}
            >
                <span className={currentPage==='Policies'?'menu-btn-selected':''}>Policies</span>          
                
            </Button>
            </div>
            <div style={{verticalAlign: "middle",display:isloggedIn==='false'?"inline-block":"none"}}>
              <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleSignupClick}
              >
                  <span className={currentPage==='Signup'||currentPage==='VerifyEmail'||currentPage==='VerifyEmailAfterSignup'?'menu-btn-selected':''}>Sign up</span>
              </Button>
              <Menu
                  keepMounted
                  anchorEl={signupAnchorEl}
                  onClose={handleSignupFormClose}
                  open={Boolean(signupAnchorEl)}
              >
                  <MenuItem onClick={handleSignupClose}>Signup</MenuItem>
                  <MenuItem onClick={handleVerifyEmailClose}>Verify Email</MenuItem>
              </Menu>
            </div>
            <div style={{verticalAlign: "middle",display:isloggedIn==='false'?"inline-block":"none"}}>
                <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleSigninClick}
                >
                <span className={currentPage==='LoginForm'||currentPage==='LoginAfterSignup'?'menu-btn-selected':''}>Sign in</span>                
                </Button>
            </div>
            <div style={{verticalAlign: "middle",display:isloggedIn==='false'?"none":"inline-block"}}>
                <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleLogoutClick}
                >
                <span>Logout</span>
                </Button>
                <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleProfileClick}
                >
                <span className={currentPage==='Profile'?'menu-btn-selected':''}>Profile</span>
                </Button>
            </div>
        </div>
    );
  }
  
  export default MenuBar1;
  